import React from 'react'
import {Link} from 'gatsby'
import logo from '../images/logo.png'
import ButtonCall from './ButtonCall'


export default (props) => {
    // let colorFondo = props.color=='azul' ? 'bg-blue-900' : 'bg-gray-300'
    // let colorTexto = props.color=='azul' ? 'text-white' : 'text-blue-800'
    const colorFondo='bg-gray-300'
    const colorTexto='text-cheflojo'
    return (
        <div className={`bg-opacity-100 fixed top-0 z-40 items-center shadow-lg
        ${colorFondo} w-full py-2 px-4`}>
            <nav className={`m-auto max-w-screen-xl grid grid-cols-1 
            md:grid-cols-3 items-center md:py-0
            text-sm`}>
                <div className=" col-span-1 left-0 ">
                    <Link to="/"><img src={logo} width='150px' alt="logo" className="m-auto md:mx-24"/></Link>
                </div>
                <ul className={`col-span-2 flex ${colorTexto} right-0 text-xs m-auto md:m-0 space-x-8
                items-center mt-4 md:my-0 font-bold justify-end `}>
                    <Link to="/" className="navlink">Home</Link> 
                    <Link to="/contacto" className="navlink">Mapa</Link> 
                    <Link to="/info" className="navlink">Servicios</Link>
                    <li className="items-center rounded-full"> <ButtonCall to="Tel:9995991337">LLAMAR AHORA</ButtonCall> </li>
                </ul>
            </nav>
        </div>
    )
}
