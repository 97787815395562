import React from 'react'
import {useStaticQuery,graphql} from 'gatsby'
import phone from '../images/phone-icon.png'

export default props => { 
    return(
        <a href={props.to}>
            <button className="bg-cheflojo hover:shadow-lg transition duration-100
            hover:bg-gray-700 text-white px-4 py-2 text-sulphur rounded-full
            flex items-center content-center" 
            href={props.to}>
                <img src={phone} alt="icon phone" width="20px" className="mr-3"/>
                {/* <Image fixed={data.phone.sharp.fixed} width="50%" alt="boton llamar"/> */}
                {props.children}
            </button>
        </a>
)
}