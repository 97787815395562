import React from 'react'
import logo from '../images/mayalav.png'
import logofb from '../images/logofb.png'
import logoig from '../images/logoig.png'
import logowa from '../images/logowa.png'


const CompFooter = () => {
    return (
        <div className="bg-gray-300">
        <div className="m-auto max-w-screen-xl text-sm grid grid-cols-1 sm:grid-cols-3 text-center py-12 sm:py-24 items-center justify-center">
           <h2 className="py-8">
               <ul className="flex items-center justify-center pb-2">
                   <li><img src={logofb} width="50px" alt="imagenredes"/></li>
                   <li><img src={logoig} width="35px" alt="imagenredes"/></li>
                   <li><img src={logowa} width="50px" alt="imagenredes"/></li>

               </ul>
               <p>Mayalav Mérida</p>
               <p>Tel. 328929838</p>
               <p>Email: alkjsd@mayalav.com</p>
           </h2>
           <h2 className="py-8">
               <img src={logo} width="100px" className="m-auto" alt="logo"/>
           </h2>
           <h2 className="text-lg py-8 m-auto max-w-sm"><h1 className="text-blue-700 pb-2">Sucursales</h1>
              <div className="text-xs grid grid-cols-4 sm:grid-cols-1 ">
               <p>Brisass</p>
               <p>Macroplaza</p>
               <p>Dorada</p>
               <p>Caucel</p>
                </div>
           </h2>
        </div>
        </div>
    )
}

export default CompFooter
